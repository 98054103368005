:root {
  --bokamera-primary: #007bff;
  --bokamera-primary-active: #0069d9;
  --bokamera-success-light: #cbf2dc;
  --bokamera-success: #8eddb0;
  --bokamera-text-success: #3c9412;
  --bokamera-danger-light: #d3a6a6;
  --bokamera-danger: #ff5a44;
  --bokamera-grey-100: #f8fafc;
  --bokamera-grey-200: #f1f5f8;
  --bokamera-grey-300: #dae1e7;
  --bokamera-grey-400: #b8c2cc;
  --bokamera-grey-500: #8795a1;
  --bokamera-grey-600: #606f7b;
  --bokamera-grey-700: #3d4852;
  --bokamera-grey-800: #22292f;
  --bokamera-white: #fff;
  --bokamera-black: #000;
  --bokamera-border: 1px solid rgba(240, 237, 237, 0.5);
  --bokamera-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  --bokamera-text-color: var(--bokamera-black);
  --bokamera-bg-color: #fff;
  --bokamera-btn-bg: var(--bokamera-grey-200);
  --bokamera-btn-bg-active: var(--bokamera-grey-300);
  --bokamera-input-bg: var(--bokamera-white);
  --bokamera-input-border: var(--bokamera-grey-300);
}
:root[data-theme='dark'] {
  --bokamera-primary: #5da9fb;
  --bokamera-grey-800: #f8fafc;
  --bokamera-grey-700: #f1f5f8;
  --bokamera-grey-600: #dae1e7;
  --bokamera-grey-500: #b8c2cc;
  --bokamera-grey-400: #8795a1;
  --bokamera-grey-300: #606f7b;
  --bokamera-grey-200: #3d4852;
  --bokamera-grey-100: #22292f;
  --bokamera-bg-color: rgba(256, 256, 256, 0.07);
  --bokamera-border: none;
  --bokamera-box-shadow: none;
  --bokamera-text-color: var(--bokamera-grey-800);
  --bokamera-btn-bg: var(--bokamera-grey-300);
  --bokamera-btn-bg-active: var(--bokamera-grey-200);
  --bokamera-input-bg: var(--bokamera-grey-200);
  --bokamera-input-border: var(--bokamera-grey-300);
}

[data-theme="dark"] body {
  background: var(--bokamera-grey-200);
}

body {
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

#bokamera-embedded-profile {
  font-family: 'Roboto', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  white-space: pre-line;
  color: var(--bokamera-grey-800);
  min-height: 640px;

  .container,
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .card {
    border: var(--bokamera-border);
    box-shadow: var(--bokamera-box-shadow);
    background-color: var(--bokamera-bg-color);
    margin-bottom: 1rem;
  }
  .nav-pills .nav-link {
    cursor: pointer;
  }
  .nav-pills .nav-link.active,
  .nav-pills .nav-item.show .nav-link {
    border: var(--bokamera-border);
    background-color: var(--bokamera-bg-color);
    border-bottom-color: var(--bokamera-grey-300);
  }
  .nav-pills .nav-link:hover,
  .nav-pills .nav-link:focus {
    border-color: var(--bokamera-grey-300);
    border-bottom-color: var(--bokamera-bg-color);
  }
  .nav-pills .nav-link.active {
    color: var(--bokamera-primary);
    background-color: var(--bokamera-bg-color);
    border-bottom: 1px solid var(--bokamera-primary);
    z-index: 1;
  }
  .nav-pills {
    border-bottom: 1px solid var(--bokamera-grey-300);
  }
  .form-control,
  .form-control:focus {
    border: 1px solid var(--bokamera-input-border);
    background-color: var(--bokamera-input-bg);
    color: var(--bokamera-text-color);
  }
  .btn-secondary {
    border-color: var(--bokamera-grey-300);
    background-color: var(--bokamera-btn-bg);
    color: var(--bokamera-text-color);
  }
  .dropdown-menu {
    border: 1px solid var(--bokamera-grey-300);
  }
  .dropdown-menu,
  .dropdown-item {
    background-color: var(--bokamera-grey-200);
    color: var(--bokamera-text-color);
  }
  .btn-secondary:hover {
    background-color: var(--bokamera-btn-bg-active);
    border-color: var(--bokamera-grey-400);
    color: var(--bokamera-text-color);
  }
  .dropdown-item:hover {
    color: var(--bokamera-primary);
  }
  .table {
    color: var(--bokamera-text-color);
  }
}

dt {
  font-weight: normal;
  color: #888;
}

.BookingsPage {
  padding: 40px;
  height: 100%;

  &__nav {
    margin-bottom: 20px;
  }
  &__listCol {
    flex-basis: 60%;
    flex-grow: 1;
    overflow-x: scroll;
    
  }
  &__customerCol {
    flex-basis: 40%;
  }
  &__spinner {
    display: block;
    margin: 40px auto;
  }
  &__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  @media only screen and (max-width: 800px) {
    padding: 20px 16px;

    &__content {
      width: 100%;
      flex-direction: column;
    }
    &__listCol {
      width: 100%;
      margin-right: 0;
    }
    &__customerCol {
      width: 100%;
    }
  }
}

.BookingCard {
  max-width: 720px;
  margin: 0 auto 10px;
  padding: 20px;

  .hidden {
    display: none;
  }

  &__img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-clip: padding-box;
    background-position: center center;
    background-size: cover;
    -moz-background-clip: padding;
    margin-bottom: 20px;
    &--small {
      width: 50px;
      height: 50px;
      margin-bottom: 0;
    }
  }
  &__flex {
    transition: all 0.7s cubic-bezier(0.45, 0, 0.55, 1);
    display: flex;
    &.itemCenter {
      align-items: center;
    }
    &.justifyCenter {
      justify-content: center;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__right {
    margin-left: 40px;
    flex-grow: 1;
  }
  &__serviceName {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 12px;
  }
  &__cardReview {
    overflow: hidden;
    transition: max-height 0.7s cubic-bezier(0.45, 0, 0.55, 1);
    &__heading {
      font-weight: 600;
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 12px;
    }
  }
  &__subtitle {
    margin-bottom: 12px;
  }
  &__id {
    color: #888;
  }
  &__datetime {
    margin-bottom: 12px;
  }
  &__time {
    display: inline-block;
  }
  &__date {
    display: inline-block;
  }
  &__descriptionList {
    max-width: 320;
    margin: 0;
    display: flex;
  }
  &__descriptionTitle {
    margin-right: 10px;
  }
  &__descriptionDetails {
    text-align: right;
  }
  &__action {
    margin-bottom: 10px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__formError {
    margin-top: 15px;
    color: var(--bokamera-danger);
  }
  &__textarea {
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid var(--bokamera-input-border);
    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
  &__input {
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid var(--bokamera-input-border);
    line-height: 1.5;
    height: calc(1.5em + 0.75rem + 2px);
    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
  @media only screen and (max-width: 800px) {
    &__time {
      font-size: 1.2rem;
    }
    &__img {
      width: 80px;
      height: 80px;
    }
  }
  @media only screen and (max-width: 600px) {
    &__img {
      width: 48px;
      height: 48px;
    }
    &__right {
      margin-left: 16px;
    }
    &__time {
      font-size: 1.2rem;
    }
  }
  &__SmallCard {
    transition: all 0.7s cubic-bezier(0.45, 0, 0.55, 1);
    .BookingCard__right {
      transition: all 0.7s cubic-bezier(0.45, 0, 0.55, 1);
      justify-content: center;
      display: flex;
      margin: 0;
      transform: translate(0px, 0px);
    }
    &.active {
      @media only screen and (max-width: 600px) {
        min-width: 100% !important;
      }
      .BookingCard__right {
        transform: translate(-25px, 0px);
      }
    }
  }
  &__serviceName {
    margin-top: 10px;
    font-weight: bold;
  }
  &__teaserList {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    @media only screen and (max-width: 600px) {
      margin-left: auto;
      margin-right: auto;
    }
    .card {
      padding: 8px;
      margin-left: 8px;
      margin-right: 8px;
      scroll-snap-align: start;
      @media only screen and (max-width: 600px) {
        min-width: 90%;
      }
      flex-grow: 1;
      min-width: 300px;
      max-width: 300px;
    }
    .alert {
      flex-basis: calc(25% - 16px);
    }
  }
}

.Login {
  padding: 0 16px;

  &__title {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
  &__form {
    max-width: 400px;
    margin: 80px auto;
  }
  &__button {
    width: 100%;
  }
  &__or {
    text-align: center;
    margin: 20px 0;
  }
  &__forgotPassword {
    float: right;
  }
  &__cancelBooking {
    margin-bottom: 20px;
  }
}

.Payment {
  padding: 20px 0;

  &__loading {
    margin: 180px auto 0;
    text-align: center;
  }
}

.CancelBooking {
  width: 55%;
  margin: 0 auto;
  &__textarea {
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px solid var(--bokamera-input-border);
    display: block;
    width: 100%;
    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
  &__header {
    text-align: center;
    background-color: var(--bokamera-white);
  }
}
