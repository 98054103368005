.Pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;

    &__pageLink,
    &__previousLink,
    &__nextLink,
    &__breakLink {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: var(--bokamera-primary);
        background-color: var(--bokamera-bg-color);
        border: 1px solid var(--bokamera-grey-200);
        box-shadow: var(--bokamera-box-shadow);
        cursor: pointer;
    }
    &__pageLink.active,
    &__previousLink.active,
    &__nextLink.active {
        z-index: 3;
        color: #fff;
        background-color: var(--bokamera-primary);
        border-color: var(--bokamera-primary);
    }
    .Pagination__page.disabled .Pagination__pageLink,
    .Pagination__previous.disabled .Pagination__previousLink,
    .Pagination__next.disabled .Pagination__nextLink,
    &__breakLink {
        color: var(--bokamera-grey-400);
        pointer-events: none;
        cursor: auto;
        background-color: var(--bokamera-bg-color);
        border-color: var(--bokamera-grey-200);
    }
    &__pageLink:hover,
    &__previousLink:hover,
    &__nextLink:hover {
        z-index: 2;
        color: #0056b3;
        text-decoration: none;
        background-color: #e9ecef;
        border-color: var(--bokamera-grey-200);
    }
    &__previousLink {
        margin-left: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
    &__nextLink {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
}
