.CustomerCard {
  &__customerName {
    font-size: 1.4rem;
    padding-bottom: 0.8rem;
  }
  &__descriptionList {
    display: flex;
    justify-content: space-between;
  }
  &__button:not(:last-of-type) {
    margin-bottom: 10px;
  }
  &__buttonLink {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
  .warning {
    color: var(--bokamera-danger);
  }
}
