.ResetPassword {
    flex-basis: 600px;
    margin: 15px auto 0;
    padding: 20px;
    &__logo {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 21px;
        font-family: 'Raleway', sans-serif;
        margin-bottom: 20px;
    }
    &__logoImage {
        width: 38px;
        height: 38px;
        margin-right: 9px;
    }
    &__logoText {
        font-size: 21px;
        font-family: Raleway, sans-serif;
    }
    &__center {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }
    &__recaptchaContainer {
        display: flex;
        justify-content: center;
    }
    &__centerPage {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    input {
        margin-bottom: 20px;
    }
    button {
        margin-bottom: 20px;
    }
    .alert {
        margin-bottom: 0;
    }
    .btn {
        padding: 0.75rem 1.25rem;
    }
}